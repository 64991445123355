import React from 'react'
import SideNav from '../components/SideNav'
import Header from '../components/Header'
import Footer from '../components/Footer'
import axios from 'axios';
import { Base_URL, Upload_History } from '../constants';
import { FcBarChart, FcCancel } from 'react-icons/fc';
import { Link, useNavigate } from 'react-router-dom';
import swal from 'sweetalert';
import { Box, Button, Tooltip } from '@mui/material';
import useFullPageLoader from '../components/hooks/useFullPageLoader';
import { DataGrid } from '@mui/x-data-grid';
import SessionExpiredAlert from '../components/SessionExpiredAlert';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { useCookies } from "react-cookie";
import HistoricalSideNav from '../components/HistoricalSideNav';
import Uploader from '../components/Uploader';

const Analyze = () => {

    sessionStorage.clear();
    
    const [cookies, removeCookie ] = useCookies(['access_token', 'sample_token','kibana_token']);

    const [data, setData] = React.useState({});

    const [loader, showLoader, hideLoader] = useFullPageLoader();

    const [page, setPage] = React.useState(0);
    const [pageSize, setPageSize] = React.useState(10);
    const [totalRows, setTotalRows] = React.useState(0);

    const columns = [
        {
          field: 'uploadTime',
          headerName: 'Upload Date',
          headerClassName: 'super-app-theme--header',
          flex: 0.5,
          cellClassName: (params) => {
            return 'word-wrap';
          },
          valueGetter: (params) => {
            if (params.value === '2022-03-15T10:00:00.000Z') {
              return '-';
            }
            const date = new Date(params.value);
            const formattedDate = date.toLocaleDateString('en-US', { day: '2-digit', month: 'short', year: 'numeric' });
            const formattedTime = date.toLocaleTimeString('en-US', { hour: '2-digit', minute:'2-digit', second: '2-digit', hour12: true });
            return `${formattedDate} ${formattedTime}`;
          },
        },      
        { field: 'id', headerClassName: 'super-app-theme--header', headerName: 'Upload Tag', flex: 0.5, cellClassName: (params) => {
          return 'word-wrap';
        } },
        {
          field: 'productType',
          headerClassName: 'super-app-theme--header',
          headerName: 'Product Type',
          flex: 0.3,
          cellClassName: 'word-wrap',
          valueGetter: (params) => {
            const productTypeMapping = {
              qlik_sense: 'Qlik Sense',
              qlik_view: 'Qlik View',
              nprinting: 'N-Printing',
              script_log: 'Script Log'
            };
            return productTypeMapping[params.row.productType] || params.row.productType;
          }
        },        
        { field: 'ingestionStatus', headerClassName: 'super-app-theme--header', headerName: 'Status Data', flex: 0.5, cellClassName: (params) => {
          return 'word-wrap';
        }, valueGetter: (params) => {
            const status = params.value;
            if (status === 'completed') {
              return 'Ingestion-Completed';
            } else if (status === 'pending') {
              return 'Ingestion-Pending';
            } else if (status === 'in_progress') {
              return 'Ingestion-In Progress';
            }
             else {
              return status;
            }
          }, },
          {
            field: 'actions',
            headerName: 'Actions',
            headerClassName: 'super-app-theme--header',
            flex: 0.5,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) => {
              if (params.row.id === 'Sample Data') {
                const uploadTag = params.row.id;
                const qlikversion = params.row.qlikVersion;
                const to = `/analyzestaticlogs/${uploadTag}/${qlikversion}/3`;
                const handleCall = () => {
                  
                  var data = JSON.stringify({
                    "dash_type": "sample_dashboard",
                    "upload_tag": cookies.sample_upload_tag
                  });

                  var config = {
                    method: 'post',
                    url: Base_URL + '/users/dashboard',
                    headers: { 
                      'Content-Type': 'application/json'
                    },
                    data : data
                  };

                  axios(config)
                  .then(function (response) {
                    
                  })
                  .catch(function (error) {
                    
                  });

                };
                return (
                  <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                    <Link to={to} onClick={handleCall}>
                      <Button style={{backgroundColor: '#060694', fontFamily: 'Poppins, sans-serif' }} variant="contained">Sample Dashboard</Button>
                    </Link>
                  </div>
                );
              } else {
                const uploadTag = params.row.id;
                const qlikversion = params.row.qlikVersion;
                const to = `/analyzestaticlogs/${uploadTag}/${qlikversion}/3`;

                const userRole = localStorage.getItem('userRole');

                const isTrialUser = userRole === 'trialuser';

                const handleApiCall = () => {
                  
                  var data = JSON.stringify({
                    "dash_type": "static_dashboard",
                    "upload_tag": uploadTag
                  });

                  var config = {
                    method: 'post',
                    url: Base_URL + '/users/dashboard',
                    headers: { 
                      'Content-Type': 'application/json'
                    },
                    data : data
                  };

                  axios(config)
                  .then(function (response) {
                    
                  })
                  .catch(function (error) {
                    
                  });

                };

                const handleDelete = () => {
                  swal({
                    title: 'Are you sure you want to delete the file upload history?',
                    icon: 'warning',
                    buttons: true,
                    dangerMode: true,
                  })
                  .then((willDelete) => {
                    if (willDelete) {
                      showLoader();
                      const tenant = localStorage.getItem('tenant_name');
          
                      var data = JSON.stringify({
                        "upload_tag": uploadTag
                      });
                      
                      var config = {
                        method: 'delete',
                        url: Base_URL + '/uploadhistory/' + tenant,
                        headers: { 
                          'Content-Type': 'application/json'
                        },
                        data : data
                      };

                      axios(config)
                      .then(function (response) {
                        hideLoader();
                        swal({
                          icon: 'success',
                          title: 'File Upload History Deleted Successfully'
                        }).then(function() {
                          window.location.reload();
                        });
                      })
                      .catch(function (error) {
                        console.log(error);
                      });
                    } else {
                      swal('File Upload History was not deleted.');
                    }
                  });
                };
                
                const handleTroubleshoot = () => {
                  localStorage.setItem("selectedUploadTag", uploadTag);
                  window.location.reload();
                  // navigate('/aitroubleshoot/' + uploadTag);
                };

                if(params.row.deleteStatus) {
                  return (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', fontWeight: 'bold', fontSize: '17px', fontFamily: 'Poppins, sans-serif' }}>
                      Deleted
                    </div>
                  );
                }else{
                  return (
                    <>
                    {localStorage.getItem("selectedUploadTag") === uploadTag ? (
                      <Button style={{ color: 'black', fontWeight:'bold', fontFamily: 'Poppins, sans-serif', width: '190px' }}>Currently Selected</Button>
                    ) : (
                      <Button style={{ fontFamily: 'Poppins, sans-serif', width: '190px' }} variant='outlined' onClick={handleTroubleshoot}>Select</Button>
                    )}
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      {/* <Link to={to} onClick={handleApiCall}>
                        <FcBarChart size={30} />
                      </Link> */}
                      <div style={{ marginLeft: '8px', marginRight: '8px' }}></div>
                      <Tooltip title={isTrialUser ? 'WoWizer TPM Test Drive does not have this functionality. Kindly proceed with the complete sign-up to unlock all features.' : ''} arrow>
                      <span>
                        <DeleteForeverIcon
                          onClick={isTrialUser ? null : handleDelete}
                          style={{
                            cursor: isTrialUser ? 'not-allowed' : 'pointer',
                            fontSize: 30,
                            color: 'red',
                          }}
                          disabled={isTrialUser}
                        />
                      </span>
                    </Tooltip>
                    </div>
                    {/* <Button variant='outlined' onClick={handleTroubleshoot}>AI Troubleshoot</Button> */}
                    </>
                  );
                }
              }
            },
          },
    ];

    React.useEffect(() => {
        fetchData('');
    }, []);

    const fetchData = async (page) => {
      const tenant = localStorage.getItem('tenant_name');
      const userRole = localStorage.getItem('userRole');
    
      var config = {
        method: 'get',
        url: Base_URL + Upload_History + tenant + page,
        headers: {}
      };
    
      axios(config)
        .then(function (response) {
          let uploadHistory = response.data.data.uploadhsitory.map((element) => {
            return {
              id: element.uploadTag,
              uploadTime: element.uploadTime,
              productType: element.productType,
              ingestionStatus: element.ingestionStatus,
              qlikVersion: element.qlikVersion,
              deleteStatus: element.deleteStatus
            };
          });
    
          if (tenant === 'd293axplcju2mtyyodq') {
            const newRecord = {
              id: 'BOB-11Jul2024-NB-Logs-7-11-2024-12-12-45-PM-QV21',
              uploadTime: new Date('2024-07-11T12:12:45.000Z').toISOString(),
              ingestionStatus: 'Ingestion-Completed',
              qlikVersion: 'QV21',
              deleteStatus: false
            };
            uploadHistory.push(newRecord);
          }
    
          uploadHistory.sort((a, b) => {
            if (a.deleteStatus === b.deleteStatus) {
              return new Date(b.uploadTime) - new Date(a.uploadTime);
            }
            return a.deleteStatus ? 1 : -1;
          });
    
          const sampleData = {
            id: 'Sample Data',
            uploadTime: new Date('2022-03-15T10:00:00.000Z').toISOString(),
            productType: 'qlik_sense',
            ingestionStatus: 'Ingestion-Completed',
            qlikVersion: 'QV00'
          };
          uploadHistory.unshift(sampleData);
    
          if (userRole === 'trialuser') {
            uploadHistory = [sampleData];
          }
    
          setData(uploadHistory);
          setTotalRows(uploadHistory.length);
        })
        .catch(function (error) {
          console.log(error);
        });
    };    
  

    const CustomFooter = () => null;

    const navigate = useNavigate();

    const handleRedirect = () => {
      localStorage.setItem("logtype", "sample");
      navigate('/analyzestaticlogs/Sample Data/QV00/3');
      var data = JSON.stringify({
        "dash_type": "sample_dashboard",
        "upload_tag": cookies.sample_upload_tag
      });

      var config = {
        method: 'post',
        url: Base_URL + '/users/dashboard',
        headers: { 
          'Content-Type': 'application/json'
        },
        data : data
      };

      axios(config)
      .then(function (response) {
        
      })
      .catch(function (error) {
        
      });

    };

  return (
    <>
    <SessionExpiredAlert />
    <HistoricalSideNav />
    <Header />
    <div>
        <div className="content-wrapper">
        <div className="uploader-container" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginLeft: '1%' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div className="uploader">
              <Uploader />
            </div>
            <button
              style={{
                backgroundColor: '#060694',
                color: 'white',
                height: '50px',
                width: '200px',
                marginLeft: '10px',
              }}
              type="button"
              className="btn sample-btn"
              onClick={handleRedirect}
            >
              <span>Try with Sample Data</span>
            </button>
          </div>
          {/* <div style={{ marginRight: '2%', fontSize: '14px' }}>
          &nbsp;&nbsp;Currently Selected:
          <Button>
             {localStorage.getItem("selectedUploadTag")}
          </Button>
          </div> */}
        </div>
            <br />
            <Box style={{ height: 450, width: '98%', marginLeft: '1%' }}
            sx={{
              '& .super-app-theme--header': {
                backgroundColor: '#060694',
                color: 'white',
                fontSize: '14px'
              },
            }}
            >
                <DataGrid
                rows={data}
                // rowCount={totalRows}
                // rowsPerPageOptions={[10]}
                // rowHeight={50}
                // pagination
                // page={page}
                // pageSize={pageSize}
                // onPageChange={(newPage) => setPage(newPage)}
                // onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                columns={columns}
                getRowClassName={(params) =>
                  localStorage.getItem("selectedUploadTag") === params.row.id ? 'selected-row' : ''
                }
                className='second-step'
                // disableSelectionOnClick
                components={{
                    Footer: CustomFooter,
                }}
                />
            </Box>
        </div>
    </div>    
    {loader}
    <Footer />
    </>
  )
}

export default Analyze